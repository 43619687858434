const SVGComponent = (props) => (
    <svg
        viewBox="0 0 1008 707"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M622.767 564.4C487.528 641.862 351.555 688.069 241.124 700.249C185.903 706.34 137.216 703.906 98.2494 692.732C59.3014 681.564 30.2555 661.73 13.8703 633.123C9.51813 625.525 6.15177 617.469 3.74332 609H0.62667C3.11084 617.987 6.64412 626.543 11.2671 634.615C78.7933 752.507 353.239 722.237 624.258 567.003C895.278 411.769 1060.24 190.356 992.715 72.4637C937.833 -23.3542 746.266 -21.2996 530.945 66.1714C532.414 66.4335 533.783 66.9875 534.988 67.7714C616.567 34.891 694.593 14.3586 762.858 6.82908C818.079 0.738333 866.766 3.17221 905.733 14.3461C944.681 25.5146 973.727 45.3482 990.112 73.9548C1006.5 102.561 1008.91 137.65 998.838 176.896C988.761 216.161 966.228 259.388 933.036 303.938C866.657 393.03 758.006 486.938 622.767 564.4Z"
            fill="#59C5C7"
        />
    </svg>
);
export default SVGComponent;