import * as React from "react";
const SVGComponent = (props) => (
    <svg
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M68.0013 5.19902C68.355 4.31874 69.357 3.88918 70.2246 4.2729C80.2504 8.70697 88.5574 16.3221 93.8429 25.9625C99.4041 36.1058 101.276 47.8635 99.1402 59.2324C97.0043 70.6012 90.9924 80.8778 82.129 88.311C73.2655 95.7442 62.0989 99.8741 50.5318 99.9972C38.9647 100.12 27.7128 96.2287 18.6932 88.9857C9.67366 81.7427 3.44455 71.5964 1.06726 60.2755C-1.31004 48.9547 0.311555 37.1598 5.65575 26.9005C10.735 17.1499 18.8782 9.35975 28.8073 4.71343C29.6666 4.31134 30.6775 4.71949 31.0498 5.59205C31.4222 6.46462 31.0149 7.47096 30.1567 7.87522C20.9581 12.2081 13.4147 19.442 8.70264 28.4877C3.72565 38.042 2.21547 49.0265 4.42943 59.5695C6.64338 70.1125 12.4445 79.5617 20.8443 86.307C29.2441 93.0523 39.7229 96.6764 50.4953 96.5619C61.2676 96.4473 71.6669 92.6011 79.9214 85.6786C88.1758 78.7562 93.7746 69.1857 95.7638 58.598C97.753 48.0103 96.0095 37.0604 90.8304 27.6141C85.9271 18.6707 78.2314 11.5989 68.9428 7.46268C68.0762 7.07676 67.6476 6.07931 68.0013 5.19902Z"
            fill="#F5F5F5"
        />
        <circle cx={50.0001} cy={6.45161} r={6.45161} fill="#9ABA4D" />
        <path
            d="M53.7961 32.1736V21.5054H56.9892V37.6344H53.8854L46.2039 26.9892V37.6344H43.0107V21.5054H46.1146L53.7961 32.1736Z"
            fill="#F5F5F5"
        />
    </svg>
);
export default SVGComponent;
