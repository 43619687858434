export const InstagramSvg = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9999 8.74956C10.205 8.74956 8.74994 10.2046 8.74994 11.9996C8.74994 13.7945 10.205 15.2496 11.9999 15.2496C13.7949 15.2496 15.2499 13.7945 15.2499 11.9996C15.2499 10.2046 13.7949 8.74956 11.9999 8.74956Z"
      fill="#6166AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.76948 3.08103C10.2176 2.69565 13.7823 2.69565 17.2304 3.08103C19.1288 3.2932 20.66 4.78898 20.8828 6.69403C21.2951 10.219 21.2951 13.7801 20.8828 17.3051C20.66 19.2101 19.1288 20.7059 17.2304 20.9181C13.7823 21.3035 10.2176 21.3035 6.76948 20.9181C4.87108 20.7059 3.33989 19.2101 3.11707 17.3051C2.70479 13.7801 2.70479 10.219 3.11707 6.69403C3.33989 4.78898 4.87108 3.2932 6.76948 3.08103ZM16.9999 5.99956C16.4477 5.99956 15.9999 6.44727 15.9999 6.99956C15.9999 7.55184 16.4477 7.99956 16.9999 7.99956C17.5522 7.99956 17.9999 7.55184 17.9999 6.99956C17.9999 6.44727 17.5522 5.99956 16.9999 5.99956ZM7.24994 11.9996C7.24994 9.3762 9.37659 7.24956 11.9999 7.24956C14.6233 7.24956 16.7499 9.3762 16.7499 11.9996C16.7499 14.6229 14.6233 16.7496 11.9999 16.7496C9.37659 16.7496 7.24994 14.6229 7.24994 11.9996Z"
      fill="#6166AF"
    />
  </svg>
);

export const YouTubeSvg = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.98888 4.89006C10.3246 4.62909 13.6755 4.62909 17.0112 4.89006L19.2519 5.06536C20.5 5.16301 21.521 6.0984 21.7273 7.33317C22.2435 10.4227 22.2435 13.5764 21.7273 16.6659C21.521 17.9007 20.5 18.8361 19.2519 18.9337L17.0112 19.109C13.6755 19.37 10.3246 19.37 6.98888 19.109L4.74818 18.9337C3.50011 18.8361 2.47909 17.9007 2.27278 16.6659C1.75657 13.5764 1.75657 10.4227 2.27278 7.33317C2.47909 6.0984 3.50011 5.16301 4.74818 5.06536L6.98888 4.89006ZM10 14.4697V9.5294C10 9.29621 10.2544 9.15218 10.4543 9.27215L14.5713 11.7423C14.7655 11.8588 14.7655 12.1403 14.5713 12.2568L10.4543 14.7269C10.2544 14.8469 10 14.7029 10 14.4697Z"
      fill="#6166AF"
    />
  </svg>
);

export const FacebookSvg = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.2 2.875C12.9734 2.875 11.797 3.36228 10.9296 4.22963C10.0623 5.09699 9.575 6.27337 9.575 7.5V10.075H7.1C6.97574 10.075 6.875 10.1757 6.875 10.3V13.7C6.875 13.8243 6.97574 13.925 7.1 13.925H9.575V20.9C9.575 21.0243 9.67574 21.125 9.8 21.125H13.2C13.3243 21.125 13.425 21.0243 13.425 20.9V13.925H15.9219C16.0252 13.925 16.1152 13.8547 16.1402 13.7546L16.9902 10.3546C17.0257 10.2126 16.9183 10.075 16.7719 10.075H13.425V7.5C13.425 7.29446 13.5067 7.09733 13.652 6.95199C13.7973 6.80665 13.9945 6.725 14.2 6.725H16.8C16.9243 6.725 17.025 6.62426 17.025 6.5V3.1C17.025 2.97574 16.9243 2.875 16.8 2.875H14.2Z"
      fill="#6166AF"
    />
  </svg>
);


