import React from "react";
import { Col, Row } from "antd";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import PanoramaImage from "../../images/panorama.jpg";
import "./Panoram.css";

const Panoram = () => {
    return (
        <section id="all-in-one">
            <div className="inner">
                <Row gutter={[50, 50]}>
                    <Col span={24}>
                        <h2 className="panoram-title">Панорама</h2>
                    </Col>
                    <Col span={24} className="panoram-image">
                        <ReactPhotoSphereViewer src={PanoramaImage} height={"100%"} width={"100%"} />
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Panoram;
